<template>
  <div class="contact">
    <h1>Contact</h1>
    <p class="">
      Darwin Webapplications<br />
      Brugstraat 8<br />
      6096 AB, Grathem<br />
      Nederland<br />
      <br />
      Email: info@DarwinWebapplications.nl<br />
      Tel: +31 616512991<br />
      KvK: 81422849<br />
      IBAN: NL44 RABO 0151 0011 03<br />
      Btw id: NL003558746B80<br />
    </p>
  </div>
</template>
