<template>
  <div class="disclaimer container">
    <div class="row">
    <h1>Disclaimer</h1>
        <h4>Algemeen</h4>
        <p>
            Het onderstaande is van toepassing op www.DarwinWebApplications.nl, welke volledig in
            eigendom is van Darwin Web Applications. Door de website te gebruiken ga je akkoord
            met de gebruikersvoorwaarden.
        </p>
        <h4>Informatieverschaffing en aansprakelijkheid</h4>
        <p>
            Darwin Web Applications streeft ernaar om juiste en actuele informatie te verschaffen.
            Hoewel dit met grote zorgvuldigheid wordt gedaan, staan wij niet garant voor de
            informatie. Gebruik van informatie wordt op eigen risico gedaan. De website kan links
            bevatten van derden. Darwin Web Applications is niet verantwoordelijk voor de inhoud
            daarvan.
        </p>
        <h4>Intellectuele eigendomsrechten</h4>
        <p>
            Alle intellectuele rechten van de websites en publicaties liggen bij Darwin Web
            Applications. Er mag niets uit deze website en publicaties vermenigvuldigd, gekopieerd
            of op een andere manier geopenbaard worden, zonder schriftelijke toestemming van Darwin
            Web Applications.
        </p>
        <h4>Privacy-verklaring</h4>
        <p>
            Darwin Web Applications eerbiedigt de privacy van de bezoekers van de website en
            behandelt de persoonlijke gegevens die de bezoeker van de website aan Darwin Web
            Applications verschaft, vertrouwelijk en in overeenstemming met de Algemene
            Verordening Gegevensbescherming (AVG).
        </p>
        <h4>Vragen</h4>
        <p>
            Mochten er vragen zijn, dan kunt u deze stellen via
            <a href="mailto:info@DarwinWebApplications.nl?SUBJECT=Disclaimervraag">
                info@DarwinWebApplications.nl.
            </a>
        </p>
    </div>
  </div>
</template>
